import { FC } from 'react'

type Props = {
  size: number
  [x: string]: any
}

const ItemExchangeIcon: FC<Props> = ({ size = 32, ...rest }) => {
  const { className: c, ...args } = rest
  const className = c ?? ''
  return (
    <svg
      className={className}
      width={size}
      height={size}
      {...args}
      viewBox="0 0 56 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.4731 24.1315C42.344 23.8647 42.1317 23.647 41.868 23.5113L28.1169 16.6358C27.926 16.5404 27.7155 16.4907 27.5022 16.4907C27.2888 16.4907 27.0784 16.5404 26.8875 16.6358L13.1363 23.5113C12.8727 23.647 12.6603 23.8647 12.5312 24.1315L27.5022 31.6177L42.4731 24.1315Z"
        fill="#9EDB9E"
      />
      <path
        d="M12.5314 24.1316C12.433 24.3203 12.3798 24.5293 12.376 24.7421V41.2436C12.3759 41.4991 12.4469 41.7496 12.5813 41.967C12.7156 42.1844 12.9078 42.3601 13.1364 42.4743L26.8876 49.3499C27.0785 49.4451 27.289 49.4945 27.5023 49.4943V31.6177L12.5314 24.1316Z"
        fill="#72C472"
      />
      <path
        d="M27.502 31.6177V49.4943C27.7147 49.4966 27.9251 49.4496 28.1166 49.3568L41.8678 42.4812C42.0975 42.3664 42.2904 42.1897 42.4248 41.971C42.5592 41.7523 42.6297 41.5003 42.6283 41.2436V24.7421C42.6244 24.5293 42.5712 24.3203 42.4729 24.1316L27.502 31.6177Z"
        fill="#4DA34D"
      />
      <path
        d="M38.4923 7.00306C38.5096 6.84004 38.4979 6.67525 38.458 6.51627C38.458 6.50527 38.447 6.49702 38.4442 6.48602C38.4 6.33805 38.3312 6.19859 38.2407 6.07348C38.2099 6.03744 38.1774 6.00302 38.1431 5.97035C38.0628 5.87875 37.9702 5.7987 37.868 5.73245C37.846 5.71732 37.8337 5.69395 37.8103 5.68157L28.1844 0.181093C27.9761 0.0620478 27.7401 -0.000384522 27.5001 1.78186e-06C27.2601 0.000388086 27.0244 0.0635796 26.8164 0.183295C26.6084 0.303011 26.4354 0.475082 26.3145 0.682404C26.1936 0.889726 26.129 1.12508 26.1273 1.36507V5.56056C19.087 5.91535 12.4516 8.95896 7.58996 14.0634C2.72834 19.1678 0.0115012 25.9437 0 32.9928C0 33.3575 0.144879 33.7073 0.402763 33.9652C0.660648 34.2231 1.01042 34.3679 1.37512 34.3679C1.73982 34.3679 2.08959 34.2231 2.34748 33.9652C2.60536 33.7073 2.75024 33.3575 2.75024 32.9928C2.75759 26.6683 5.18258 20.5858 9.52862 15.991C13.8747 11.3962 19.8129 8.63678 26.1273 8.2778V12.366C26.1268 12.6076 26.19 12.8451 26.3105 13.0545C26.431 13.264 26.6045 13.4379 26.8137 13.559C27.0228 13.68 27.2601 13.7437 27.5017 13.7439C27.7433 13.744 27.9807 13.6804 28.1899 13.5596L37.8158 8.05915C37.9808 7.9681 38.1222 7.8395 38.2283 7.68374C38.267 7.64062 38.3024 7.59466 38.3342 7.54623C38.3466 7.52423 38.3452 7.50085 38.3562 7.47885C38.4109 7.36828 38.4502 7.25077 38.4731 7.12957C38.469 7.08557 38.4868 7.04431 38.4923 7.00306Z"
        fill="#335262"
      />
      <path
        d="M55.0046 32.9928C55.0046 32.6281 54.8597 32.2783 54.6018 32.0204C54.3439 31.7626 53.9942 31.6177 53.6295 31.6177C53.2648 31.6177 52.915 31.7626 52.6571 32.0204C52.3992 32.2783 52.2544 32.6281 52.2544 32.9928C52.247 39.3174 49.822 45.3998 45.476 49.9946C41.1299 54.5894 35.1917 57.3488 28.8773 57.7078V53.6196C28.8778 53.378 28.8146 53.1405 28.6941 52.9311C28.5736 52.7217 28.4001 52.5477 28.1909 52.4267C27.9818 52.3056 27.7445 52.2419 27.5029 52.2418C27.2613 52.2416 27.0239 52.3052 26.8146 52.426L17.1888 57.9265C17.1599 57.943 17.1407 57.9691 17.1132 57.9883C17.0589 58.0253 17.0079 58.0667 16.9605 58.1121C16.8793 58.1852 16.8073 58.2678 16.746 58.3582C16.7268 58.3885 16.6951 58.4064 16.6773 58.438C16.6594 58.4696 16.6663 58.4834 16.6553 58.5054C16.6004 58.6159 16.5611 58.7334 16.5384 58.8547C16.5301 58.8987 16.5122 58.9413 16.5067 58.9922C16.4895 59.1552 16.5012 59.32 16.5411 59.479C16.5411 59.49 16.5521 59.4982 16.5549 59.5092C16.6575 59.8169 16.8591 60.0819 17.1283 60.2628C17.1503 60.2779 17.1627 60.3013 17.1861 60.3137L26.8119 65.8141C27.0212 65.9356 27.2589 65.9997 27.5009 66C27.7429 66.0002 27.9807 65.9366 28.1903 65.8155C28.3998 65.6944 28.5737 65.5201 28.6943 65.3103C28.8149 65.1005 28.8781 64.8626 28.8773 64.6205V60.4251C35.9175 60.0703 42.553 57.0267 47.4146 51.9222C52.2762 46.8178 54.9931 40.0419 55.0046 32.9928Z"
        fill="#335262"
      />
    </svg>
  )
}

export default ItemExchangeIcon
