import { useState } from 'react'

const useImageApi = (fileName: string) => {
  const imageCDN = process.env.REACT_APP_CDN_URI
  const [src, setSrc] = useState<string>(`${imageCDN}${fileName}`)

  const updateSrc = (newFileName: string) => setSrc(`${imageCDN}${newFileName}`)

  return { src, updateSrc }
}

export default useImageApi
