import { FC } from 'react'
import { EmptyContainer } from './parts'
import { LoadingIcon } from './parts/icons'
import PageBase from './PageBase'

type Props = {
  [x: string]: any
}

const LoadingScreen: FC<Props> = ({}) => {
  return (
    <PageBase
      body={
        <EmptyContainer className="text-center max-w-full h-screen pt-24 bg-white">
          <div className="flex flex-col justify-center items-center">
            <LoadingIcon size={36} className="animate-reverse-spin mt-4" />
          </div>
        </EmptyContainer>
      }
      withHeader={false}
      withFooter={false}
    />
  )
}

export default LoadingScreen
