import {
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext,
  MutableRefObject,
  ChangeEvent,
} from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import 'wagmi/window'

import { context as StateContext } from '../../view/contexts/state'
import useClaimApi from '../useClaimApi'
import useDocumentTitle from '../useDocumentTitle'
import { toHash } from '../../utils'

type ClaimApi = ReturnType<typeof useClaimApi>

const useLoginPage = (): {
  token: string | null
  ref: MutableRefObject<HTMLInputElement | null>
  error: ClaimApi['error']
  loading: ClaimApi['loading']
  loadingConfig: boolean
  needDisclaimer: boolean
  needToS: boolean
  agreed: boolean
  emailValue: string
  onAgreed: (e: ChangeEvent<HTMLInputElement>) => void
  onEmailEntered: (e: ChangeEvent<HTMLInputElement>) => void
  onLogin: () => void
} => {
  const token = useSearchParams()[0].get('token')
  const { setState, state } = useContext(StateContext)
  const { loading, error, getClaim, getConfig } = useClaimApi({ token: token })
  useDocumentTitle('NFT Redeeming Service | Login')
  const [agreed, setAgreed] = useState(false)
  const [emailValue, setEmailValue] = useState('')
  const ref = useRef<HTMLInputElement>(null)
  const navigate = useNavigate()

  const onAgreed = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setAgreed(event.target.checked)
      setState((prevState) => ({
        ...prevState,
        agreement: event.target.checked,
        hash: null,
      }))
    },
    [setState]
  )

  const onEmailEntered = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setEmailValue(e.target.value),
    [setEmailValue]
  )

  const onLogin = useCallback(() => {
    const callback = () => {
      getClaim(ref.current?.value).then((claim) => {
        if (claim && claim.status < 2) navigate(`/redeem?token=${token}`)
        if (claim && claim.status >= 2) navigate(`/completed?token=${token}`)
      })
    }
    setState(
      (prevState) => ({
        ...prevState,
        email: ref.current?.value,
        hash: toHash({ email: ref.current?.value, token: token }),
      }),
      callback
    )
  }, [navigate, ref, getClaim, setState, token])

  useEffect(() => {
    if (!state.config) getConfig(token ?? '')
  }, [token])

  return {
    token,
    ref,
    error,
    loading,
    loadingConfig: !state.config,
    needDisclaimer: isMobile && !window.ethereum,
    needToS: !state.config?.hideToS,
    agreed: state.config?.hideToS ? true : agreed,
    emailValue,
    onAgreed,
    onEmailEntered,
    onLogin,
  }
}

export default useLoginPage
