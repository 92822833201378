import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { Web3Button } from '@web3modal/react'

import useRedeemNFTPage from '../../hooks/pages/useRedeemNFTPage'
import PageBase from '../components/PageBase'
import LoadingScreen from '../components/LoadingScreen'
import { Button, Card, Lottie, NFTDetail, Tooltip } from '../components/parts'
import { WarningIcon, QuestionIcon } from '../components/parts/icons'
import checkmark from '../../checkmark.json'

const RedeemNFTPage: FC = () => {
  const {
    loadingConfig,
    NFT,
    isRedeemable,
    isConnected,
    onSignMessage,
    isRejected,
    hasApiFailed,
    signedAnimation,
    onRedeemingEnd,
  } = useRedeemNFTPage()

  if (loadingConfig) return <LoadingScreen />
  if (!NFT)
    return (
      <PageBase
        body={
          <Card className="text-center">
            <FormattedMessage id="loading" />
          </Card>
        }
      />
    )

  return (
    <PageBase
      headerAddon={
        <div className="mr-4 my-2 flex justify-center">
          <h2 className="ml-2 my-auto font-medium text-sm md:text-base text-white">
            <FormattedMessage id="redeem.title" />
          </h2>
        </div>
      }
      body={
        <Card className="text-center">
          <NFTDetail data={NFT!}>
            {signedAnimation ? (
              <Lottie
                data={checkmark}
                width={36}
                height={36}
                onEnd={onRedeemingEnd}
                className="mx-auto mt-6"
              />
            ) : (
              <div className="flex flex-col items-center">
                <h3 className="text-sm mt-6 mb-4 flex items-center gap-1">
                  <span>
                    <FormattedMessage id="redeem.connect" />
                  </span>
                  <Tooltip
                    message={<FormattedMessage id="redeem.connect.tooltip" />}
                  >
                    <QuestionIcon size={16} />
                  </Tooltip>
                </h3>
                {isConnected ? (
                  <div className="connected">
                    <Web3Button balance="hide" />
                  </div>
                ) : (
                  <div className="not-connected">
                    <Web3Button balance="hide" />
                  </div>
                )}
                <h3 className="text-sm mt-6 mb-4">
                  <FormattedMessage id="redeem.sign" />
                </h3>
                <Button
                  primary
                  className="w-[164px]"
                  disabled={!isRedeemable}
                  onClick={onSignMessage}
                >
                  <FormattedMessage id="redeem.sign.button" />
                </Button>
              </div>
            )}
            {isRejected && (
              <div className="text-[#EE2121] flex justify-center mt-2">
                <WarningIcon className="mx-0.5 sm:mx-2" size={16} />
                <p>
                  <FormattedMessage id="redeem.sign.rejected" />
                </p>
              </div>
            )}
            {hasApiFailed && (
              <div className="text-[#EE2121] flex justify-center mt-2">
                <WarningIcon className="mx-0.5 sm:mx-2" size={16} />
                <p>
                  <FormattedMessage id="redeem.api.error" />
                </p>
              </div>
            )}
          </NFTDetail>
        </Card>
      }
    />
  )
}

export default RedeemNFTPage
