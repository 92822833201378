import { FC, ReactNode, useContext, SyntheticEvent } from 'react'
import { FormattedMessage } from 'react-intl'
import { context as StateContext } from '../../contexts/state'

type Props = {
  [x: string]: any
}

const Footer: FC<Props> = () => {
  const { state, setState } = useContext(StateContext)
  const needPoweredBy = !state.config?.hidePoweredBy

  const onClickLanguage = (event: SyntheticEvent<HTMLButtonElement>) => {
    const newLanguage = event.currentTarget.name
    setState((prevState) => ({
      ...prevState,
      locale: newLanguage,
    }))
  }
  return (
    <footer>
      <FooterFlex>
        <FooterRow className="w-11/12 h-12 text-[#717194] text-xs mt-16 mx-auto px-4 py-6 flex justify-between border-t border-t-[#DFE0EB]">
          <div className="md:w-32 md:block"></div>
          <button
            name="ja"
            disabled={state.locale === 'ja'}
            className="underline disabled:no-underline"
            onClick={onClickLanguage}
          >
            日本語
          </button>
          <button
            name="en"
            disabled={state.locale === 'en'}
            className="underline disabled:no-underline"
            onClick={onClickLanguage}
          >
            English
          </button>
          <div className="md:w-32 md:block"></div>
        </FooterRow>
        {needPoweredBy && (
          <FooterRow className="h-12 text-[#717194] text-xs mt-8 mx-auto">
            <FormattedMessage id="footer.poweredby" />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={'https://www.curvegrid.com/services/nfteapot'}
            >
              <FormattedMessage id="footer.nfteapot" />
            </a>
          </FooterRow>
        )}
      </FooterFlex>
    </footer>
  )
}

export default Footer

const FooterFlex: FC<{ children: ReactNode | ReactNode[] }> = ({
  children,
}) => {
  return <div className="flex flex-col">{children}</div>
}

const FooterRow: FC<{
  children: ReactNode | ReactNode[]
  className?: string
  id?: string
}> = ({ children, className, id }) => {
  return (
    <div id={id} className={className}>
      {children}
    </div>
  )
}
