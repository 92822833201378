import { FC, ReactNode } from 'react'

type Props = {
  children: ReactNode
  [x: string]: any
}

const EmptyContainer: FC<Props> = ({ children, ...rest }) => {
  const { className: c, ...args } = rest
  const fixedStyle = ['container', 'mx-auto', 'p-10', '']
  const className = fixedStyle.join(' ') + (c ?? '')
  return (
    <div className={className} {...args}>
      {children}
    </div>
  )
}

export default EmptyContainer
