import { FC } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import PageBase from '../components/PageBase'
import LoadingScreen from '../components/LoadingScreen'
import {
  Button,
  Checkbox,
  EmptyContainer,
  TextInput,
} from '../components/parts'
import { GiftIcon, WarningIcon, LoadingIcon } from '../components/parts/icons'
import useLoginPage from '../../hooks/pages/useLoginPage'

const LoginPage: FC = () => {
  const {
    token,
    ref,
    error,
    loading,
    loadingConfig,
    needDisclaimer,
    needToS,
    agreed,
    emailValue,
    onAgreed,
    onEmailEntered,
    onLogin,
  } = useLoginPage()

  const { locale } = useIntl()
  const ToSLink =
    locale === 'en'
      ? 'https://www.curvegrid.com/utility-pages/nfteapot-terms-and-conditions'
      : 'https://ja.curvegrid.com/utility-pages/nfteapot-terms-and-conditions'

  if (!token)
    return (
      <PageBase
        body={
          <EmptyContainer className="text-center">
            <h2 className="mt-10 text-[#EC7274] mb-4">
              <FormattedMessage id="login.lost" />
            </h2>
            <WarningIcon className="mx-auto mb-6" size={80} />
            <p className="font-medium mb-6">
              <FormattedMessage id="login.missing_token" />
            </p>
          </EmptyContainer>
        }
      />
    )

  if (loadingConfig) return <LoadingScreen />

  if (needDisclaimer)
    return (
      <PageBase
        body={
          <EmptyContainer className="text-center p-8">
            <h2 className="mt-10 text-[#EC7274] mb-4">Disclaimer!</h2>
            <p className="md:w-2/3 mx-auto font-medium mb-6">
              <FormattedMessage id="login.disclaimer" />
            </p>
            <TextInput
              type="email"
              className="m-2 sm:w-96"
              forwardedRef={ref}
              onChange={onEmailEntered}
            >
              <FormattedMessage id="login.emailAddress" />
            </TextInput>
            {needToS && (
              <Checkbox
                className="flex justify-center items-center mt-4 w-full"
                onChange={onAgreed}
              >
                <span className="text-xs cursor-text leading-4 text-left inline-block">
                  <FormattedMessage id="login.agreement" />
                  <a href={ToSLink} target="_blank" rel="noopener noreferrer">
                    <FormattedMessage id="login.tos" />
                  </a>
                </span>
              </Checkbox>
            )}
            <Button
              className="m-2 !h-12 md:w-80 uppercase"
              disabled={!agreed || !emailValue}
              onClick={onLogin}
            >
              <FormattedMessage id="login.signin" />
            </Button>
            {loading && (
              <div className="flex justify-center items-center">
                <FormattedMessage id="login.loading" />
                <LoadingIcon size={12} className="animate-reverse-spin ml-2" />
              </div>
            )}
            {error && (
              <div className="text-[#EE2121] flex justify-center mt-1">
                <WarningIcon className="mx-2 my-1" size={16} />
                <span>
                  <FormattedMessage id="login.error" />
                </span>
              </div>
            )}
          </EmptyContainer>
        }
      />
    )

  return (
    <PageBase
      body={
        <EmptyContainer className="text-center">
          <GiftIcon className="mx-auto mb-6" size={80} />
          <h2 className="text-[#7979F4] mb-2">
            <FormattedMessage id="login.welcome" />
          </h2>
          <p className="font-medium mb-6">
            <FormattedMessage id="login.welcome_p" />
          </p>
          <TextInput
            type="email"
            className="m-2 sm:w-96"
            forwardedRef={ref}
            onChange={onEmailEntered}
          >
            <FormattedMessage id="login.emailAddress" />
          </TextInput>
          {needToS && (
            <Checkbox
              className="flex justify-center items-center mt-4 w-full"
              onChange={onAgreed}
            >
              <span className="text-xs cursor-text leading-4 text-left inline-block">
                <FormattedMessage id="login.agreement" />
                <a href={ToSLink} target="_blank" rel="noopener noreferrer">
                  <FormattedMessage id="login.tos" />
                </a>
              </span>
            </Checkbox>
          )}
          <Button
            className="my-4 mx-2 !h-12 md:w-80 uppercase"
            disabled={!agreed || !emailValue}
            onClick={onLogin}
          >
            <div>
              <FormattedMessage id="login.signin" />
            </div>
          </Button>
          {loading && (
            <div className="flex justify-center items-center">
              <FormattedMessage id="login.loading" />
              <LoadingIcon size={12} className="animate-reverse-spin ml-2" />
            </div>
          )}
          {error && (
            <div className="text-[#EE2121] flex justify-center">
              <WarningIcon className="mx-2 my-1" size={16} />
              <span>
                <FormattedMessage id="login.error" />
              </span>
            </div>
          )}
        </EmptyContainer>
      }
    />
  )
}

export default LoginPage
