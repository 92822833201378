import { FC, ReactNode, useCallback, useMemo } from 'react'
import { NFT } from '../../../model'
import useImageApi from '../../../hooks/useImageApi'

type Props = {
  data: NFT
  children?: Array<ReactNode>
  [x: string]: any
}
type KeyValue = { [x: string]: string }

const NFTDetail: FC<Props> = ({ data, children, ...rest }) => {
  const { className: c, ...args } = rest
  const className = 'text-xs ' + c
  const { src } = useImageApi(data['imageFile'])
  const order = useMemo(
    () => [
      'name',
      'imageFile',
      'externalLink',
      'blockchain',
      'ipfsLink',
      'description',
      ...(data && data['properties'] ? Object.keys(data['properties']) : []),
    ],
    [data]
  )
  const namePairs: KeyValue = useMemo(
    () => ({
      imageFile: 'Image',
      externalLink: 'External Link',
      ipfsLink: 'IPFS Link',
      creatorMsg: 'Message',
      contractAddr: 'Contract Address',
    }),
    []
  )

  const renderItems = useCallback(() => {
    if (!data) return []
    return Object.entries(data)
      .map(([key, value]) =>
        key === 'properties' ? (
          Object.entries(value).map(([k, v]: [k: string, v: string]) => (
            <li className="--cg-nft-list" key={k}>
              <div className="--cg-nft-list-key">{k}</div>
              <div className="--cg-nft-list-value">{v}</div>
            </li>
          ))
        ) : (
          <li className="--cg-nft-list" key={key}>
            <div className="--cg-nft-list-key">
              {key in namePairs ? namePairs[key] : key}
            </div>
            <div className="--cg-nft-list-value">
              {key === 'imageFile' ? (
                <img
                  alt={`NFT-${data['name']}`}
                  className="max-h-28 mx-auto"
                  src={src}
                ></img>
              ) : (
                <>{value}</>
              )}
            </div>
          </li>
        )
      )
      .flat()
      .sort(function (a, b) {
        return order.indexOf(a.key as string) - order.indexOf(b.key as string)
      })
  }, [data, src, order, namePairs])

  if (!data) <>missing data</>

  return (
    <ul className={className} {...args}>
      {renderItems()}
      {children && <li key="child">{children}</li>}
    </ul>
  )
}

export default NFTDetail
