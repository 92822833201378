import { FC, ReactNode } from 'react'

type Props = {
  children: ReactNode
  [x: string]: any
}

const Header: FC<Props> = ({ children }) => {
  return (
    <header className="w-full h-12 flex justify-between bg-[#060921]">
      {children}
    </header>
  )
}

export default Header
