import { FC } from 'react'

type Props = {
  size: number
  [x: string]: any
}

const WalletIcon: FC<Props> = ({ size = 32, ...rest }) => {
  const { className: c, ...args } = rest
  const className = c
  return (
    <svg
      className={className}
      {...args}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
    >
      <path
        d="M6.00033 0.166748C2.78033 0.166748 0.166992 2.78008 0.166992 6.00008C0.166992 9.22008 2.78033 11.8334 6.00033 11.8334C9.22033 11.8334 11.8337 9.22008 11.8337 6.00008C11.8337 2.78008 9.22033 0.166748 6.00033 0.166748ZM6.58366 10.0834H5.41699V8.91675H6.58366V10.0834ZM7.79116 5.56258L7.26616 6.09925C6.97449 6.39675 6.76449 6.66508 6.65949 7.08508C6.61283 7.27175 6.58366 7.48175 6.58366 7.75008H5.41699V7.45842C5.41699 7.19008 5.46366 6.93341 5.54533 6.69425C5.66199 6.35591 5.85449 6.05258 6.09949 5.80758L6.82283 5.07258C7.09116 4.81591 7.21949 4.43091 7.14366 4.02258C7.06783 3.60258 6.74116 3.24675 6.33283 3.13008C5.68533 2.94925 5.08449 3.31675 4.89199 3.87092C4.82199 4.08675 4.64116 4.25008 4.41366 4.25008H4.23866C3.90033 4.25008 3.66699 3.92342 3.76033 3.59675C4.01116 2.73925 4.74033 2.08591 5.64449 1.94591C6.53116 1.80591 7.37699 2.26675 7.90199 2.99591C8.59033 3.94675 8.38616 4.96758 7.79116 5.56258Z"
        fill="#40406A"
      />
    </svg>
  )
}

export default WalletIcon
