import { FC } from 'react'

type Props = {
  size: number
  [x: string]: any
}

const WarningIcon: FC<Props> = ({ size = 32, ...rest }) => {
  const { className: c, ...args } = rest
  const className = c ?? ''
  return (
    <svg
      viewBox="0 0 56 54"
      className={className}
      width={size}
      height={size}
      {...args}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.3892 45.8667L32.4887 2.71538C32.0595 1.89631 31.4143 1.2103 30.6231 0.731661C29.8318 0.253023 28.9247 0 28 0C27.0753 0 26.1682 0.253023 25.3769 0.731661C24.5857 1.2103 23.9405 1.89631 23.5113 2.71538L0.610759 45.8667C0.187619 46.6574 -0.0222367 47.5446 0.00186501 48.4411C0.0259668 49.3375 0.283194 50.2122 0.748207 50.979C1.1961 51.7341 1.83313 52.3592 2.59642 52.7929C3.35971 53.2265 4.22288 53.4537 5.10076 53.4518H50.8992C51.7771 53.4537 52.6403 53.2265 53.4036 52.7929C54.1669 52.3592 54.8039 51.7341 55.2518 50.979C55.7168 50.2122 55.974 49.3375 55.9981 48.4411C56.0222 47.5446 55.8124 46.6574 55.3892 45.8667Z"
        fill="#EEC121"
      />
      <path
        d="M30.9095 16.5444L29.4548 37.6339H26.5455L25.0908 16.5444H30.9095Z"
        fill="#060921"
      />
      <path
        d="M27.9997 48.3611C30.1083 48.3611 31.8177 46.6518 31.8177 44.5431C31.8177 42.4345 30.1083 40.7251 27.9997 40.7251C25.891 40.7251 24.1816 42.4345 24.1816 44.5431C24.1816 46.6518 25.891 48.3611 27.9997 48.3611Z"
        fill="#060921"
      />
    </svg>
  )
}

export default WarningIcon
