import { FC, Suspense, useState } from 'react'
import { Route, Routes, Navigate } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import { IntlProvider, FormattedMessage } from 'react-intl'
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'

import { Provider as StateProvider, State } from './view/contexts/state'
import updater from './view/contexts/updater'
import PageBase from './view/components/PageBase'
import LoginPage from './view/pages/LoginPage'
import RedeemNFTPage from './view/pages/RedeemNFTPage'
import CompletedPage from './view/pages/CompletedPage'
import useTranslation from './hooks/useTranslation'
import EmptyContainer from './view/components/parts/EmptyContainer'
import { availableChains } from './model'

const walletConnectProjectId = process.env.REACT_APP_WALLET_CONNECT_KEY

if (!walletConnectProjectId) {
  throw new Error('unable to use WalletConnect')
}

// Wagmi client
const { publicClient } = configureChains(availableChains, [
  w3mProvider({ projectId: walletConnectProjectId }),
])
const config = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({
    projectId: walletConnectProjectId,
    version: 2,
    chains: availableChains,
  }),
  publicClient,
})

// Web3Modal Ethereum Client
const ethereumClient = new EthereumClient(config, availableChains)

const App: FC = () => {
  const email = localStorage.getItem('email')
  const agreement = localStorage.hasOwnProperty('agreement')
  const hash = localStorage.getItem('hash')
  const { messages, locale, setLocale, loading } = useTranslation()
  const [globalState, setGlobalState] = useState<State>({
    email: email,
    agreement: agreement,
    hash: hash,
    claim: null,
    locale: locale,
  })
  const updateState = (
    updateFn: (prevState: State) => State,
    callback?: Function
  ) => {
    updater(updateFn, setGlobalState, setLocale, callback)
  }

  const initialContext = { state: globalState, setState: updateState }

  if (loading) return <></>

  if (!messages)
    return (
      <IntlProvider
        locale={locale}
        messages={{ failed: 'failed to initialize' }}
        defaultLocale="en"
      >
        <PageBase
          withFooter={false}
          body={
            <EmptyContainer>
              <FormattedMessage id="failed" />
            </EmptyContainer>
          }
        />
      </IntlProvider>
    )

  return (
    <StateProvider value={initialContext}>
      <Suspense fallback={<div />}>
        <IntlProvider locale={locale} messages={messages} defaultLocale="en">
          <WagmiConfig config={config}>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<LoginPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/redeem" element={<RedeemNFTPage />} />
                <Route path="/completed" element={<CompletedPage />} />
                <Route
                  path="*"
                  element={
                    <Navigate replace to={`/login${window.location.search}`} />
                  }
                />
              </Routes>
            </BrowserRouter>
          </WagmiConfig>
          <Web3Modal
            projectId={walletConnectProjectId}
            ethereumClient={ethereumClient}
            themeMode="light"
            enableAccountView={false}
            themeVariables={{
              '--w3m-accent-color': '#08086D',
              '--w3m-background-color': '#08086D',
              '--w3m-accent-fill-color': 'white',
            }}
          />
        </IntlProvider>
      </Suspense>
    </StateProvider>
  )
}

export default App
