import { avalanche, avalancheFuji, polygon, polygonMumbai } from 'wagmi/chains'
export const availableChains = [
  avalanche,
  avalancheFuji,
  polygon,
  polygonMumbai,
]
type AvailableChainNames = (typeof availableChains)[number]['name']

type Config = {
  hideToS?: boolean
  hidePoweredBy?: boolean
}

type Claim = {
  status: number
  challenge?: string // the challenge to be signed (only for status == 0)
  walletAddr?: string // only for status > 0
  contractAddr?: string // only for status > 1
  txHash?: string // only for status > 1
  txLink?: string // only for status > 1 (e.g. <Block explorer URL>/tx/<txHash>)
  marketplaceLink?: string // not always available and only for status > 1
  nft: NFT
}

type NFT = {
  imageFile: string // the image file name (e.g. xyz.jpg)
  name: string // the NFT name
  blockchain?: AvailableChainNames // the blockchain
  externalLink?: string
  description?: string
  creatorMsg?: string
  properties?: { [key: string]: string } // a map of NFT properties
  ipfsLink: string // the NFT IPFS link
}

type ClaimRequestBody = { address: `0x${string}`; signature: string }

export default Claim
export type { Config, NFT, ClaimRequestBody }
