import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import useCompletedPage from '../../hooks/pages/useCompletedPage'
import PageBase from '../components/PageBase'
import LoadingScreen from '../components/LoadingScreen'
import { Card, NFTDetail, Lottie } from '../components/parts'
import {
  ShieldIcon,
  ItemExchangeIcon,
  LoadingIcon,
  OpenseaIcon,
  OwnershipIcon,
} from '../components/parts/icons'
import checkmark from '../../checkmark.json'

const CompletedPage: FC = () => {
  const {
    loading,
    loadingConfig,
    error,
    NFT,
    hash,
    link,
    marketplaceLink,
    isMinting,
    mintedAnimation,
    isCompleted,
    onEnd,
    refetch,
    isIndifferent,
  } = useCompletedPage()

  if (loadingConfig) return <LoadingScreen />
  if (error) return <>error</>
  if (!NFT)
    return (
      <PageBase
        body={
          <Card className="text-center">
            <FormattedMessage id="loading" />
          </Card>
        }
      />
    )

  return (
    <PageBase
      headerAddon={
        isCompleted ? (
          <div className="mr-4 my-2 flex justify-center">
            <ShieldIcon size={20} className="my-auto" />
            <h2 className="ml-2 my-auto font-medium text-sm md:text-base text-white">
              <FormattedMessage id="completed.owned" />
            </h2>
          </div>
        ) : (
          isMinting && (
            <div className="mr-4 my-2 flex justify-center">
              <ItemExchangeIcon size={20} className="my-auto" />
              <h2 className="ml-2 my-auto font-medium text-sm md:text-base text-white">
                <FormattedMessage id="completed.minting" />
              </h2>
            </div>
          )
        )
      }
      body={
        <Card className="text-center">
          <NFTDetail data={NFT!}>
            {isMinting && (
              <>
                <ItemExchangeIcon size={54} className="block mx-auto mt-4" />
                <div className="flex justify-center mt-2 mb-4">
                  <p>
                    <FormattedMessage id="completed.signing_done" />
                    <span className="my-1 block">
                      <FormattedMessage id="completed.recheck" />
                      <button
                        className="text-[#2121EE]"
                        onClick={() => refetch()}
                      >
                        <FormattedMessage id="completed.here" />
                      </button>
                      {loading && (
                        <LoadingIcon
                          size={12}
                          className="animate-reverse-spin inline ml-2 mb-1"
                        />
                      )}
                    </span>
                    {isIndifferent && (
                      <span>
                        <FormattedMessage id="completed.indifferent" />
                      </span>
                    )}
                  </p>
                </div>
              </>
            )}
            {mintedAnimation ? (
              <Lottie
                data={checkmark}
                width={36}
                height={36}
                onEnd={onEnd}
                className="mx-auto mt-6"
              />
            ) : (
              isCompleted && (
                <div className="flex flex-col gap-y-2">
                  <h3 className="text-sm mt-6 mb-4">
                    <FormattedMessage id="completed.success" />
                  </h3>
                  <div className="flex items-center text-left mx-4 md:mx-6 gap-x-4">
                    <div className="flex-initial basis-14">
                      <OwnershipIcon size={60} />
                    </div>
                    <div className="flex-auto">
                      <p className="text-xs my-1">
                        <FormattedMessage id="completed.tx" />
                      </p>
                      <a
                        className="font-medium break-all"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={link}
                      >
                        {hash}
                      </a>
                    </div>
                  </div>
                  {marketplaceLink && (
                    <div className="flex items-center text-left mx-4 md:mx-6 gap-x-4">
                      <div className="flex-initial basis-14">
                        <OpenseaIcon size={60} />
                      </div>
                      <div className="flex-auto">
                        <p className="text-xs mt-3 mb-1">
                          <FormattedMessage
                            id="completed.marketplace"
                            values={{
                              br: <br />,
                            }}
                          />
                        </p>
                        <a
                          className="font-medium break-all"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={marketplaceLink}
                        >
                          {marketplaceLink}
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              )
            )}
          </NFTDetail>
        </Card>
      }
    />
  )
}

export default CompletedPage
