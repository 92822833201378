import { useEffect, useState, useCallback } from 'react'
import axios from 'axios'

const availableLocales = ['en', 'ja']

const useTranslation = (): {
  locale: string
  messages: Record<string, string> | undefined
  error: Error | undefined | null
  loading: boolean
  setLocale: (locale: string) => void
  getTranslation: (locale: string) => Promise<void>
} => {
  const userLocale: string = navigator.language.substring(0, 2)
  const preferredLocale = localStorage.getItem('locale')
  const [locale, setLocale] = useState(
    availableLocales.includes(preferredLocale ?? userLocale)
      ? preferredLocale ?? userLocale
      : 'en' // as fallback default
  )
  const [messages, setMessages] = useState<Record<string, string>>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | undefined | null>()

  const getTranslation = useCallback(async (locale): Promise<void> => {
    try {
      setError(null)
      const { data } = await axios.get(`/translations/${locale}.json`)
      setMessages(data)
    } catch (err: any) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    getTranslation(locale)
  }, [getTranslation, locale])

  return { locale, messages, error, loading, setLocale, getTranslation }
}

export default useTranslation
