import { FC, ReactNode } from 'react'

type Props = {
  children: ReactNode
  message: ReactNode
  [x: string]: any
}

const Tooltip: FC<Props> = ({ children, message, ...rest }) => {
  const { className: _className, ...args } = rest
  const fixedStyle = '--cg-tooltip'
  const className = fixedStyle + (_className ?? '')

  return (
    <div className={className} {...args}>
      {children}
      <p>{message}</p>
    </div>
  )
}

export default Tooltip
