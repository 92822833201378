import { FC } from 'react'

type Props = {
  size: number
  [x: string]: any
}

const GiftIcon: FC<Props> = ({ size = 32, ...rest }) => {
  const { className: c, ...args } = rest
  const className = c ?? ''
  return (
    <svg
      className={className}
      width={size}
      height={size}
      {...args}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M53.239 0C45.7976 0 41.5797 6.18261 39.4999 10.6135C37.4202 6.18261 33.2022 0 25.7608 0C23.4834 0 21.2993 0.904695 19.6889 2.51506C18.0785 4.12543 17.1738 6.30955 17.1738 8.58696C17.1738 10.8644 18.0785 13.0485 19.6889 14.6589C21.2993 16.2692 23.4834 17.1739 25.7608 17.1739H53.239C55.5164 17.1739 57.7006 16.2692 59.3109 14.6589C60.9213 13.0485 61.826 10.8644 61.826 8.58696C61.826 6.30955 60.9213 4.12543 59.3109 2.51506C57.7006 0.904695 55.5164 0 53.239 0ZM25.7608 13.7391C24.3943 13.7391 23.0839 13.1963 22.1176 12.2301C21.1514 11.2639 20.6086 9.9534 20.6086 8.58696C20.6086 7.22052 21.1514 5.91004 22.1176 4.94382C23.0839 3.9776 24.3943 3.43478 25.7608 3.43478C32.1924 3.43478 35.6976 10.1738 37.0956 13.7391H25.7608ZM53.239 13.7391H41.894C43.2885 10.1755 46.7817 3.43478 53.239 3.43478C54.6055 3.43478 55.916 3.9776 56.8822 4.94382C57.8484 5.91004 58.3912 7.22052 58.3912 8.58696C58.3912 9.9534 57.8484 11.2639 56.8822 12.2301C55.916 13.1963 54.6055 13.7391 53.239 13.7391Z"
        fill="#F7BF26"
      />
      <path
        d="M72.13 24.0435H6.86914V73.8478C6.86914 75.2142 7.41196 76.5247 8.37818 77.4909C9.3444 78.4572 10.6549 79 12.0213 79H66.9778C68.3443 79 69.6548 78.4572 70.621 77.4909C71.5872 76.5247 72.13 75.2142 72.13 73.8478V24.0435Z"
        fill="#EE2121"
      />
      <path
        d="M75.5652 13.7393H3.43478C2.52382 13.7393 1.65017 14.1011 1.00602 14.7453C0.361878 15.3894 0 16.2631 0 17.174V27.4784C0 28.3893 0.361878 29.263 1.00602 29.9071C1.65017 30.5513 2.52382 30.9132 3.43478 30.9132H75.5652C76.4762 30.9132 77.3498 30.5513 77.994 29.9071C78.6381 29.263 79 28.3893 79 27.4784V17.174C79 16.2631 78.6381 15.3894 77.994 14.7453C77.3498 14.1011 76.4762 13.7393 75.5652 13.7393Z"
        fill="#FF5353"
      />
      <path
        d="M48.087 13.7393H30.9131V30.9132H48.087V13.7393Z"
        fill="#FFD764"
      />
      <path d="M48.087 30.9131H30.9131V79H48.087V30.9131Z" fill="#F7BF26" />
    </svg>
  )
}

export default GiftIcon
