import { FC, ReactNode } from 'react'

type Props = {
  children: ReactNode
  primary?: boolean
  secondary?: boolean
  disabled?: boolean
  [x: string]: any
}

const Button: FC<Props> = ({
  children,
  primary,
  secondary,
  disabled = false,
  ...rest
}) => {
  const { className: c, ...args } = rest
  const fixedStyle = [
    '--cg-button',
    disabled
      ? 'bg-[#DFE0EB] hover:bg-[#DFE0EB] active:bg-[#DFE0EB]'
      : primary
      ? 'bg-[#08086D] hover:bg-[#0C0C9C] active:bg-[#1010CC]' // dark blue
      : secondary
      ? 'bg-[#40406A] hover:bg-[#060921] active:bg-[#717194]' // grey
      : 'bg-[#3898EC] hover:bg-[#076FC9] active:bg-[#B5D8F8]', // blue
    '',
  ]
  const className = fixedStyle.join(' ') + (c ?? '')
  return (
    <button className={className} disabled={disabled} {...args}>
      {children}
    </button>
  )
}

export default Button
