import { FC, ReactNode } from 'react'
import Header from '../components/parts/Header'
import Footer from '../components/parts/Footer'

type Props = {
  headerAddon?: ReactNode
  body?: ReactNode
  withHeader?: boolean
  withFooter?: boolean
  [x: string]: any
}

const PageBase: FC<Props> = ({
  headerAddon,
  body,
  withHeader = true,
  withFooter = true,
}) => {
  return (
    <>
      {withHeader && (
        <Header>
          <div className="block md:w-32"></div>
          {headerAddon}
          <div className="hidden md:w-32 md:block"></div>
        </Header>
      )}
      {body}
      {withFooter && <Footer />}
    </>
  )
}

export default PageBase
