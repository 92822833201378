import React, { useRef, useState, useEffect } from 'react'
import lottie, { AnimationItem } from 'lottie-web'

type Props = {
  data: any
  loop?: boolean
  paused?: boolean
  segments?: any[]
  height?: number
  width?: number
  onEnd?: any //func,
  [x: string]: any
}

const Lottie: React.FC<Props> = ({
  data,
  loop,
  paused,
  segments,
  height = 100,
  width = 100,
  onEnd,
  ...args
}) => {
  const { className } = args
  const container = useRef<HTMLDivElement>(null)
  const [animation, setAnimation] = useState<AnimationItem | null>(null)

  useEffect(() => {
    if (!animation) return
    if (paused) {
      animation!.stop()
    } else {
      animation!.play()
    }
  }, [paused, animation])

  useEffect(() => {
    const options = {
      container: container.current as HTMLDivElement,
      animationData: data,
      loop: loop || false,
      autoplay: !paused,
    }
    const _animation = lottie.loadAnimation(options)

    if (segments) {
      _animation.playSegments(segments)
    }

    if (typeof onEnd === 'function') {
      _animation.addEventListener('complete', onEnd)
    }

    setAnimation(_animation)

    return () => {
      _animation.destroy()
    }
  }, [data, loop, paused, segments, onEnd])

  const style = {
    width: `${width}px`,
    height: `${height}px`,
    overflow: 'hidden',
    outline: 'none',
  }

  return <div ref={container} style={style} className={className} />
}

export default Lottie
