import { useContext, useEffect, useState, useCallback, useRef } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useAccount, useSignMessage } from 'wagmi'

import { context as StateContext } from '../../view/contexts/state'
import useClaimApi from '../useClaimApi'
import useDocumentTitle from '../useDocumentTitle'
import { toHash } from '../../utils'
import { ClaimRequestBody } from '../../model'

const useRedeemNFTPage = () => {
  const navigate = useNavigate()
  const token = useSearchParams()[0].get('token')
  const { state } = useContext(StateContext)
  useDocumentTitle('NFT Redeeming Service | Sign & Redeem NFT')
  const { address, isConnected, isDisconnected } = useAccount()
  const { isSuccess: isSigned, signMessageAsync: _signMessage } =
    useSignMessage({
      message: 'Sign this message to claim your NFT',
    })

  const isFirstRender = useRef(true)
  const {
    claim,
    error: apiError,
    clearError: clearApiError,
    postClaim,
    getConfig,
  } = useClaimApi({ token: token, email: state.email })
  const hash = toHash({ email: state.email, token: token })
  const [isRedeemable, setIsRedeemable] = useState(claim?.status === 0)
  const [isRejected, setIsRejected] = useState(false)
  const { nft: NFT } = claim ?? {}
  const [signedAnimation, setSignedAnimation] = useState(false)

  const signMessage = async ({
    message,
  }: {
    message: string
  }): Promise<ClaimRequestBody> => {
    if (!address) throw Error('no address')
    const signature = await _signMessage({ message })
    if (!signature) throw Error('no signature')
    return {
      signature,
      address: address,
    }
  }

  const onSignMessage = async () => {
    if (apiError) clearApiError()
    setIsRedeemable(false)

    const requestBody = await signMessage({
      message: claim!.challenge!,
    }).catch((_) => {
      setIsRejected(true)
    })

    if (!requestBody) return setIsRedeemable(true)

    await postClaim(requestBody)
      .then((_) => onSignedAndRedeemed())
      .catch((_) => setIsRedeemable(true))
  }

  const onSignedAndRedeemed = useCallback(() => {
    setSignedAnimation(true)
    setIsRedeemable(false)
  }, [])

  const onRedeemingEnd = useCallback(() => {
    setSignedAnimation(false)
    if (claim && claim.status >= 2) navigate(`/completed?token=${token}`)
  }, [navigate, token, claim])

  useEffect(() => {
    // HINT: Initial Redirect
    if (!token) navigate(`/login`)
    if (
      !!apiError ||
      !state.email ||
      !state.hash ||
      (state.hash && state.hash !== hash)
    ) {
      navigate(`/login?token=${token}`)
    }
    if (!signedAnimation && claim && claim.status >= 2) {
      navigate(`/completed?token=${token}`)
    }
  }, [])

  useEffect(() => {
    if (claim && isFirstRender.current) {
      isFirstRender.current = false
      if (claim && claim.status >= 2) {
        navigate(`/completed?token=${token}`)
      }
    }
  }, [claim])

  useEffect(() => {
    // HINT: update when web3modal changes
    setIsRedeemable(isConnected && !isDisconnected)
  }, [isConnected, isDisconnected])

  useEffect(() => {
    !isRedeemable && setIsRejected(false)
  }, [isRedeemable])

  useEffect(() => {
    if (!state.config) getConfig(token ?? '')
  }, [token])

  return {
    loadingConfig: !state.config,
    NFT,
    isRedeemable,
    isConnected,
    onSignMessage,
    isRejected,
    isSigned,
    signedAnimation,
    onRedeemingEnd,
    hasApiFailed: !!apiError,
  }
}

export default useRedeemNFTPage
