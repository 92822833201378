import { useContext, useEffect, useCallback, useState, useRef } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import { context as StateContext } from '../../view/contexts/state'
import useClaimApi from '../useClaimApi'
import useDocumentTitle from '../useDocumentTitle'
import { toHash } from '../../utils'

const useCompletedPage = () => {
  const navigate = useNavigate()
  const token = useSearchParams()[0].get('token')
  const { state } = useContext(StateContext)
  useDocumentTitle('NFT Redeeming Service | Redeem Completed!')
  const isFirstRender = useRef(true)
  const { claim, error, loading, getClaim, getConfig } = useClaimApi({
    token: token,
    email: state.email,
  })
  const [currentStatus, setCurrentStatus] = useState(claim?.status)
  const hash = toHash({ token, email: state.email })
  const { nft: NFT } = claim ?? {}
  const [isIndifferent, setIsIndifferent] = useState(false)
  const [mintedAnimation, setMintedAnimation] = useState(false)
  const [isCompleted, setIsCompleted] = useState(currentStatus === 3)

  const refetch = async () => {
    const data = await getClaim()
    if (currentStatus === data.status) {
      setIsIndifferent(true)
      setTimeout(() => setIsIndifferent(false), 2000)
    }
    if (data.status === 0) {
      navigate(`/login?token=${token}`)
    }
    if (data.status !== currentStatus) {
      setCurrentStatus(data.status)
    }
    if (data.status === 3 && !isCompleted) {
      setMintedAnimation(true)
    } else {
      data.status === 3 && setIsCompleted(true)
    }
  }

  const onEnd = useCallback(() => {
    setMintedAnimation(false)
    setIsCompleted(true)
  }, [])

  useEffect(() => {
    // HINT: Initial Redirect
    if (!token) navigate(`/login`)
    if (
      (!!token && !!error) ||
      !state.email ||
      !state.hash ||
      (state.hash && state.hash !== hash)
    ) {
      navigate(`/login?token=${token}`)
    }
    if (claim && claim.status < 2) navigate(`/redeem?token=${token}`)
  }, [])

  useEffect(() => {
    if (claim && isFirstRender.current) {
      isFirstRender.current = false
      if (claim && claim.status < 2) {
        navigate(`/redeem?token=${token}`)
      }
      setCurrentStatus(claim.status)
    }
  }, [claim])

  useEffect(() => {
    if (!loading && claim && claim.status === 3) setIsCompleted(true)
  }, [loading, claim])

  useEffect(() => {
    if (!state.config) getConfig(token ?? '')
  }, [token])

  return {
    loading,
    loadingConfig: !state.config,
    error,
    NFT,
    hash: claim?.txHash,
    link: claim?.txLink,
    marketplaceLink: claim?.marketplaceLink,
    isMinting: claim?.status === 2,
    mintedAnimation,
    isCompleted,
    onEnd,
    refetch,
    isIndifferent,
  }
}

export default useCompletedPage
