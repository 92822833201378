import { FC } from 'react'

type Props = {
  size: number
  [x: string]: any
}

const ShieldIcon: FC<Props> = ({ size = 32, ...rest }) => {
  const { className: c, ...args } = rest
  const className = c
  return (
    <svg
      className={className}
      width={size}
      height={size}
      {...args}
      viewBox="0 0 39 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.3372 4.89757L19.7122 0.980902C19.5721 0.950539 19.4272 0.950539 19.2872 0.980902L1.6622 4.89757C1.44459 4.94594 1.25001 5.06711 1.11061 5.24106C0.971204 5.415 0.895324 5.6313 0.895508 5.85421V25.4375C0.895508 30.3717 2.85558 35.1037 6.34454 38.5927C9.8335 42.0816 14.5655 44.0417 19.4997 44.0417C24.4338 44.0417 29.1658 42.0816 32.6548 38.5927C36.1438 35.1037 38.1038 30.3717 38.1038 25.4375V5.85421C38.104 5.6313 38.0281 5.415 37.8887 5.24106C37.7493 5.06711 37.5548 4.94594 37.3372 4.89757V4.89757Z"
        fill="#72C472"
      />
      <path
        d="M37.2856 4.87993L19.6606 0.963258C19.6071 0.958862 19.5534 0.958862 19.5 0.963258V44.0211C24.4235 44.0022 29.1393 42.0345 32.6159 38.5481C36.0925 35.0617 38.0471 30.3405 38.0523 25.417V5.83363C38.0518 5.61123 37.9756 5.39561 37.8363 5.22227C37.6969 5.04892 37.5027 4.92819 37.2856 4.87993V4.87993Z"
        fill="#4DA34D"
      />
      <path
        d="M27.3333 20.5417H24.3958V15.6458C24.3958 14.3474 23.88 13.1021 22.9619 12.184C22.0437 11.2658 20.7985 10.75 19.5 10.75C18.2015 10.75 16.9563 11.2658 16.0381 12.184C15.12 13.1021 14.6042 14.3474 14.6042 15.6458V20.5417H11.6667C11.407 20.5417 11.1579 20.6448 10.9743 20.8285C10.7907 21.0121 10.6875 21.2611 10.6875 21.5208V31.3125C10.6875 31.5722 10.7907 31.8212 10.9743 32.0049C11.1579 32.1885 11.407 32.2917 11.6667 32.2917H27.3333C27.593 32.2917 27.8421 32.1885 28.0257 32.0049C28.2093 31.8212 28.3125 31.5722 28.3125 31.3125V21.5208C28.3125 21.2611 28.2093 21.0121 28.0257 20.8285C27.8421 20.6448 27.593 20.5417 27.3333 20.5417ZM22.4375 20.5417H16.5625V15.6458C16.5625 14.8668 16.872 14.1196 17.4229 13.5687C17.9738 13.0178 18.7209 12.7083 19.5 12.7083C20.2791 12.7083 21.0262 13.0178 21.5771 13.5687C22.128 14.1196 22.4375 14.8668 22.4375 15.6458V20.5417Z"
        fill="#223E49"
      />
    </svg>
  )
}

export default ShieldIcon
