import { utils } from 'ethers'

type Parameters = {
  token?: string | null
  email?: string | null
}
const toHash = ({ token, email }: Parameters) =>
  utils.id(`${email ?? ''}:${token ?? ''}`)

export default toHash
