import { createContext } from 'react'
import { Claim, Config } from '../../model'

export type State = {
  email?: string | null | undefined
  agreement?: boolean
  hash?: string | null | undefined
  claim?: Claim | null | undefined
  config?: Config | undefined
  locale?: string
}

export const context = createContext({
  state: {} as State,
  setState: (updateFn: (prevState: State) => State, callback?: Function) => {
    !updateFn && (() => {})()
    !callback && (() => {})()
  },
})
export const Provider = context.Provider
