import { State } from './state'

const updater = (
  updateFn: (prevState: State) => State,
  setGlobalState: (updateFn: (prevState: State) => State) => void,
  setLocale: (newLocale: string) => void,
  callback?: Function
) => {
  setGlobalState((prevState) => {
    const newState = updateFn(prevState)

    if (newState.email === null) localStorage.removeItem('email')
    if (newState.email) localStorage.setItem('email', newState.email)
    if (newState.agreement)
      localStorage.setItem('agreement', `${newState.agreement}`)
    if (newState.hash === null) localStorage.removeItem('hash')
    if (newState.hash) localStorage.setItem('hash', newState.hash)
    if (newState.locale === null) localStorage.removeItem('locale')
    if (newState.locale) {
      localStorage.setItem('locale', newState.locale)
      setLocale(newState.locale)
    }

    return { ...prevState, ...newState }
  })

  callback && callback()
}

export default updater
