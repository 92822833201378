import { FC, ReactNode, ChangeEvent, useState } from 'react'

type Props = {
  children: ReactNode
  position?: string
  size?: number
  [x: string]: any
}

const Checkbox: FC<Props> = ({
  children,
  position = 'left',
  size = 20,
  ...rest
}) => {
  const {
    className: c,
    onChange: onChangeCallback,
    disabled,
    primary,
    secondary,
    ...args
  } = rest
  const [checked, setChecked] = useState(false)
  const onChange = (event: ChangeEvent) => {
    setChecked(!checked)
    onChangeCallback(event)
  }

  const inputStyle = 'cursor-pointer opacity-0 absolute top-0 left-0'
  const svgStyle = [
    'shrink-0 w-5 h-5 align-middle',
    'cursor-pointer select-none inline-block fill-current ease-in-out duration-300',
    !checked
      ? 'text-[#40406A] hover:text-[#40406A] active:text-[#40406A]'
      : disabled
      ? 'text-[#DFE0EB] hover:text-[#DFE0EB] active:text-[#DFE0EB]'
      : primary
      ? 'text-[#08086D] hover:text-[#0C0C9C] active:text-[#1010CC]' // dark blue
      : secondary
      ? 'text-[#EE2121] hover:text-[#880F0F] active:text-[#880F0F]' // red
      : 'text-[#3898EC] hover:text-[#076FC9] active:text-[#B5D8F8]', // blue
    position === 'left'
      ? 'mr-2 md:mr-1'
      : position === 'right'
      ? 'ml-2 md:ml-1'
      : '',
  ].join(' ')

  const rootStyle = ['align-middle m-auto relative', '']
  const className = rootStyle.join(' ') + (c ?? '')
  return (
    <label className={className}>
      {position === 'right' && children}
      <input
        type="checkbox"
        checked={checked}
        className={inputStyle}
        onChange={onChange}
        {...args}
      />
      <svg
        focusable="false"
        aria-hidden="true"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        className={svgStyle}
      >
        {checked ? (
          <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
        ) : (
          <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>
        )}
      </svg>
      {position === 'left' && children}
    </label>
  )
}

export default Checkbox
