import { FC, ReactNode } from 'react'

type Props = {
  children: ReactNode
  [x: string]: any
}

const TextInput: FC<Props> = ({ children, ...rest }) => {
  const { className: c, forwardedRef: ref, type, onChange, args } = rest
  const className = 'h-10 m-auto ' + (c ?? '')
  return (
    <div className={className} {...args}>
      <label className="relative w-full h-10">
        <span className="absolute flex items-center rounded-lg border-2 border-white h-0.5 left-2 -top-3.5 px-1 text-xs bg-white">
          {children}
        </span>
        <input
          onChange={onChange}
          type={type ? type : 'text'}
          ref={ref}
          className="w-full h-10 px-2 rounded-lg border border-[#DADAE3] hover:border-[#B0B0C1] focus:border-[#08086D] focus-visible:border-[#08086D] ease-out duration-300 "
        />
      </label>
    </div>
  )
}

export default TextInput
