import { useContext, useEffect, useState, useCallback } from 'react'
import axios from 'axios'

import { context as StateContext } from '../view/contexts/state'
import { Claim, ClaimRequestBody } from '../model'

type ClaimApiHook = {
  claim: Claim | null | undefined
  loading: boolean
  error: Error | undefined | null
  getConfig: (token: string) => Promise<void>
  getClaim: (inputEmail?: string) => Promise<Claim>
  postClaim: (body: ClaimRequestBody, callback?: () => void) => Promise<Claim>
  clearError: () => void
}

const useClaimApi = ({
  token,
  email,
  skip = false,
}: {
  token?: string | null
  email?: string | null
  skip?: boolean
}): ClaimApiHook => {
  const {
    state: { claim },
    setState,
  } = useContext(StateContext)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | undefined | null>()

  const getConfig = useCallback(async (token: string): Promise<void> => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URI}api/v0/config/${token}`
      )
      setState((prevState) => ({ ...prevState, config: data }))
    } catch (e) {
      console.warn('error: could not fetch the configuration')
      setState((prevState) => ({ ...prevState, config: {} }))
    }
  }, [])

  const getClaim = useCallback(
    async (inputEmail?: string): Promise<Claim> => {
      try {
        error && setError(null)
        setLoading(true)
        if (!token || (!email && !inputEmail))
          throw Error('no valid token or email')

        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URI}api/v0/claim`,
          {
            auth: {
              username: inputEmail?.toLowerCase()! ?? email?.toLowerCase(),
              password: token,
            },
          }
        )
        setState((prevState) => ({ ...prevState, claim: data }))
        return data
      } catch (err: any) {
        setError(err)
        throw Error(err)
      } finally {
        setLoading(false)
      }
    },
    [token, email, error, setState]
  )

  const postClaim = useCallback(
    async (
      requestBody: ClaimRequestBody,
      callback?: (data: Claim) => void
    ): Promise<Claim> => {
      try {
        setError(null)
        if (!token || !email) throw Error('no valid token or email')

        const { data } = await axios.post(
          `${process.env.REACT_APP_API_URI}api/v0/claim`,
          requestBody,
          {
            auth: {
              username: email,
              password: token,
            },
          }
        )
        setState((prevState) => ({ ...prevState, claim: data }))
        callback && callback(data)
        return data
      } catch (err: any) {
        setError(err)
        throw Error(err)
      }
    },
    [token, email, setState]
  )

  const clearError = () => setError(null)

  useEffect(() => {
    if (!skip && email && token && !claim && !error) getClaim()
  }, [skip, claim, error, email, token, getClaim])

  return { claim, loading, error, getConfig, getClaim, postClaim, clearError }
}

export default useClaimApi
