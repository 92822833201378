import { FC } from 'react'

type Props = {
  size: number
  [x: string]: any
}

const OwnershipIcon: FC<Props> = ({ size = 32, ...rest }) => {
  const { className: c, ...args } = rest
  const className = c ?? ''
  return (
    <svg
      className={className}
      width={size}
      height={size}
      {...args}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M44.707,11.293l-8-8A1,1,0,0,0,36,3H12a1,1,0,0,0-.707.293l-8,8A1,1,0,0,0,3,12V40a5.006,5.006,0,0,0,5,5H40a5.006,5.006,0,0,0,5-5V12A1,1,0,0,0,44.707,11.293ZM32.747,28.664l-8,9a1,1,0,0,1-1.494,0l-8-9A1,1,0,0,1,16,27h5V19a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1v8h5a1,1,0,0,1,.747,1.664ZM6.414,11l6-6H35.586l6,6Z"
          fill="#a4dae0"
        />
        <polygon
          points="35.586 5 12.414 5 6.414 11 41.586 11 35.586 5"
          fill="#335262"
        />
        <path
          d="M32,27H27V19a1,1,0,0,0-1-1H22a1,1,0,0,0-1,1v8H16a1,1,0,0,0-.747,1.664l8,9a1,1,0,0,0,1.494,0l8-9A1,1,0,0,0,32,27Z"
          fill="#5a7a84"
        />
      </g>
    </svg>
  )
}

export default OwnershipIcon
