import { FC } from 'react'

type Props = {
  size: number
  [x: string]: any
}

const LoadingIcon: FC<Props> = ({ size = 32, ...rest }) => {
  const { className: c, replaceFill, ...args } = rest
  const className = c
  return (
    <svg
      className={className}
      width={size}
      height={size}
      {...args}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={replaceFill}
        d="M37.9691 2.89065C33.7184 0.704353 28.9494 -0.272861 24.1817 0.0654958C19.4141 0.403853 14.8307 2.0448 10.931 4.80953L7.23738 1.6748C7.08194 1.54289 6.89378 1.45542 6.69277 1.42164C6.49175 1.38786 6.28535 1.40903 6.09537 1.48289C5.90538 1.55676 5.73887 1.68059 5.61341 1.8413C5.48796 2.00201 5.40822 2.19364 5.38263 2.39593L3.80966 14.9066C3.78959 15.0659 3.80364 15.2277 3.85088 15.3812C3.89811 15.5347 3.97746 15.6763 4.08363 15.7968C4.18981 15.9173 4.32039 16.0137 4.4667 16.0798C4.61301 16.1459 4.7717 16.1801 4.93224 16.1801C5.03511 16.1797 5.13746 16.1656 5.23665 16.1383L17.5171 12.7148C17.7152 12.6597 17.8945 12.5517 18.0359 12.4023C18.1772 12.2529 18.2752 12.0678 18.3193 11.867C18.3633 11.6661 18.3519 11.4569 18.2861 11.2621C18.2204 11.0672 18.1028 10.8939 17.946 10.7609L14.5036 7.84237C17.7517 5.78104 21.4936 4.63054 25.3383 4.51115C29.183 4.39176 32.9891 5.30788 36.3587 7.16372C39.7283 9.01956 42.5376 11.7469 44.4929 15.0607C46.4482 18.3745 47.4777 22.1529 47.4737 26.0009C47.4737 26.6014 47.7122 27.1773 48.1366 27.6019C48.5611 28.0266 49.1367 28.2651 49.737 28.2651C50.3373 28.2651 50.9129 28.0266 51.3374 27.6019C51.7618 27.1773 52.0003 26.6014 52.0003 26.0009C52.0147 21.23 50.7122 16.5477 48.2365 12.47C45.7607 8.39224 42.2078 5.07738 37.9691 2.89065Z"
        fill="#B0B0C1"
      />
      <path
        className={replaceFill}
        d="M33.0652 46.2718C30.2121 47.2702 27.1799 47.6529 24.1683 47.3949C23.8721 47.3699 23.5739 47.4035 23.2908 47.4938C23.0076 47.5841 22.7449 47.7294 22.5179 47.9212C22.0592 48.3087 21.7733 48.8625 21.7228 49.4609C21.6724 50.0593 21.8617 50.6532 22.249 51.112C22.6363 51.5708 23.1899 51.8569 23.7881 51.9073C24.5304 51.9692 25.2701 52.0002 26.0072 52.0002C28.9172 52.0002 31.8064 51.5096 34.5533 50.5488C35.1203 50.3514 35.5856 49.9368 35.8469 49.3962C36.1083 48.8555 36.1443 48.2332 35.9469 47.666C35.7496 47.0988 35.3351 46.6333 34.7947 46.3719C34.2543 46.1104 33.6322 46.0744 33.0652 46.2718Z"
        fill="#B0B0C1"
      />
      <path
        className={replaceFill}
        d="M48.7427 33.133C48.4687 33.0178 48.1746 32.9576 47.8774 32.9561C47.5801 32.9545 47.2855 33.0116 47.0103 33.124C46.735 33.2363 46.4846 33.4019 46.2734 33.6111C46.0621 33.8203 45.8941 34.069 45.779 34.3432C45.5526 34.8696 45.315 35.387 45.0547 35.8908C43.8656 38.1879 42.2684 40.2494 40.3415 41.9746C40.1158 42.1718 39.9316 42.4119 39.7994 42.6809C39.6672 42.95 39.5898 43.2426 39.5716 43.5418C39.5533 43.841 39.5947 44.1409 39.6932 44.424C39.7917 44.7071 39.9455 44.9678 40.1455 45.191C40.3455 45.4142 40.5879 45.5954 40.8585 45.7242C41.1291 45.853 41.4226 45.9267 41.7219 45.9411C42.0212 45.9555 42.3204 45.9103 42.6021 45.8081C42.8838 45.706 43.1424 45.5488 43.3629 45.3459C45.7009 43.254 47.6389 40.7541 49.0822 37.9681C49.3979 37.3557 49.6933 36.7228 49.9581 36.0934C50.189 35.5396 50.1907 34.9167 49.9628 34.3616C49.7349 33.8065 49.2961 33.3646 48.7427 33.133Z"
        fill="#B0B0C1"
      />
      <path
        className={replaceFill}
        d="M4.52653 26.0007C4.52653 25.4002 4.28808 24.8243 3.86364 24.3997C3.43919 23.9751 2.86352 23.7366 2.26327 23.7366C1.66301 23.7366 1.08734 23.9751 0.662896 24.3997C0.238451 24.8243 0 25.4002 0 26.0007C0.00155379 28.9554 0.508683 31.8879 1.49941 34.6713C1.70481 35.2312 2.12275 35.6875 2.66245 35.9409C3.20215 36.1943 3.82 36.2245 4.3818 36.0248C4.94359 35.8252 5.40394 35.4118 5.66285 34.8745C5.92175 34.3373 5.9583 33.7195 5.76454 33.1555C4.94644 30.8588 4.52772 28.4389 4.52653 26.0007Z"
        fill="#B0B0C1"
      />
      <path
        className={replaceFill}
        d="M16.1071 45.0561C13.8091 43.8608 11.7477 42.2574 10.0234 40.324C9.62349 39.876 9.06207 39.6053 8.46265 39.5715C7.86323 39.5376 7.27491 39.7434 6.82712 40.1434C6.37933 40.5435 6.10874 41.1052 6.07489 41.7048C6.04104 42.3045 6.2467 42.893 6.64662 43.341C8.73511 45.6831 11.2327 47.625 14.017 49.0716C14.2813 49.2182 14.5723 49.3103 14.8728 49.3426C15.1733 49.3748 15.4772 49.3466 15.7666 49.2594C16.056 49.1723 16.325 49.028 16.5578 48.8352C16.7906 48.6423 16.9824 48.4048 17.1219 48.1366C17.2614 47.8684 17.3459 47.575 17.3702 47.2736C17.3945 46.9722 17.3583 46.669 17.2636 46.3819C17.1689 46.0948 17.0177 45.8296 16.8188 45.6018C16.62 45.3741 16.3776 45.1886 16.1059 45.0561H16.1071Z"
        fill="#B0B0C1"
      />
    </svg>
  )
}

export default LoadingIcon
